import React, { FC, ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { createResourceLocatorString } from '../../util/routes';
import { Button, DialogWithCloseButton, FormattedMessage, TypographyWrapper } from '..';
import IconFilter from '../Icons/IconFilter/IconFilter';
import { ButtonVariant } from '../Button/Button';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import css from './FiltersModal.module.css';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import {
  trackMobileFiltersModalFilterClearAll,
  trackMobileFiltersModalSubmit,
  trackShowMobileFiltersModal,
} from '../../util/heap';
import { NO_SCROLL } from '../../Routes';

interface FiltersModalProps {
  rootClassName?: string;
  className?: string;
  children?: ReactElement | ReactElement[];
  resultsCount?: number;
  selectedFiltersCount: number;
  selectedFilters: { [key: string]: string[] | string | undefined };
  toggleIsMobileModalOpen: () => void;
  onResetAll: () => void;
  urlQueryParams: { [key: string]: string[] | string | undefined };
}

const FiltersModal: FC<FiltersModalProps> = (props) => {
  const {
    rootClassName,
    className,
    children,
    resultsCount,
    selectedFilters,
    selectedFiltersCount,
    toggleIsMobileModalOpen,
    onResetAll,
    urlQueryParams,
  } = props;

  const { isModalOpen, openModal, closeModal } = useActiveModal(ModalType.FiltersMobile);

  const [initialQueryParams, setInitialQueryParams] = useState({});

  const routes = useRouteConfiguration();
  const history = useHistory();
  const intl = useIntl();
  const { css: brandCss, shopName } = useShopConfig();

  const bgcolor = brandCss?.backgroundColor || 'white';

  const openFilters = () => {
    toggleIsMobileModalOpen();
    openModal();
    setInitialQueryParams(urlQueryParams);
    trackShowMobileFiltersModal(shopName);
  };

  // Close the filters by clicking cancel, revert to the initial params
  const cancelFilters = () => {
    history.push(
      createResourceLocatorString(
        'LandingPage',
        routes,
        {},
        { ...initialQueryParams, [NO_SCROLL]: true }
      )
    );
    toggleIsMobileModalOpen();
    closeModal();
    setInitialQueryParams({});
  };

  const closeFilters = () => {
    toggleIsMobileModalOpen();
    closeModal();

    let filters = '';

    if (!isEmpty(selectedFilters)) {
      filters = Object.entries(selectedFilters)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
    }

    trackMobileFiltersModalSubmit(filters, shopName, resultsCount);

    // scroll to the top if show items button is pressed (filters applied)
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }, 0);
  };

  const resetAll = (e: any) => {
    onResetAll();

    trackMobileFiltersModalFilterClearAll(shopName);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  const showListingsLabel = intl.formatMessage(
    { id: 'SearchFiltersMobile.showListings' },
    { count: resultsCount }
  );

  const filtersModalHeaderText = 'Filters';

  const filtersButtonClasses =
    selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <div className={css.buttons}>
        <Button
          rootClassName={filtersButtonClasses}
          onClick={openFilters}
          variant={ButtonVariant.Custom}
        >
          <IconFilter color={selectedFiltersCount > 0 ? 'white' : undefined} />
        </Button>
      </div>
      <div className={classes}>
        <DialogWithCloseButton
          fullWidth
          allowFullScreen
          open={isModalOpen}
          onClose={cancelFilters}
          headerClassName={css.dialogHeader}
        >
          <Box id="scrollableDiv" style={{ overflowY: 'auto', height: '100%' }}>
            <Box
              py={2}
              position={{ xs: 'fixed', md: 'sticky' }}
              top={0}
              zIndex={1}
              borderBottom={`solid 1px ${defaultTreetStyles.gray20}`}
              width="100%"
              bgcolor={bgcolor}
            >
              <button className={css.resetAllButton} onClick={(e) => resetAll(e)} type="button">
                <FormattedMessage id="SearchFiltersMobile.clearAll" />
              </button>
              <TypographyWrapper
                variant="h2"
                weight={TypographyWeight.Bold}
                typographyOverrides={{ align: 'center' }}
              >
                {filtersModalHeaderText}
              </TypographyWrapper>
            </Box>
            <Box px={3} mt={{ xs: 7, md: 0 }}>
              {isModalOpen ? <div className={css.filtersWrapper}>{children}</div> : null}

              <div className={css.showListingsContainer}>
                <Button className={css.showListingsButton} onClick={closeFilters}>
                  {showListingsLabel}
                </Button>
              </div>
            </Box>
          </Box>
        </DialogWithCloseButton>
      </div>
    </Box>
  );
};

export default FiltersModal;
